import React from "react";
import { Button } from '@mui/material';
import TextField from '@mui/material/TextField';
import { connect } from 'react-redux'
import { withSnackbar } from 'notistack'
import * as actions from './store/actions/index'
import { withRouter } from "react-router-dom";
import PhoneInput from 'react-phone-input-2';
import Map from './map'
import ReactLoading from "react-loading";
const minutes = [
    "00", "01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20",
    "21", "22", "23", "24", "25", "26", "27", "28", "29", "30", "31", "32", "33", "34", "35", "36", "37", "38", "39", "40",
    "41", "42", "43", "44", "45", "46", "47", "48", "49", "50", "51", "52", "53", "54", "55", "56", "57", "58", "59"
]
const hours = [
    "00", "01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"
]
const styles = {
    uploadedImgWrapper: {
        border: '2px dotted #ccc',
        maxWidth: 500,
        minHeight: 200,
        borderRadius: 5,
        margin: '0 auto',
    },
    storyContent: {
        border: '1px solid #ccc',
        borderRadius: '5px',
        padding: '20px'
    }
}

function removeItems(arr) {
    var what, a = arguments, L = a.length, ax;
    while (L > 1 && arr.length) {
        what = a[--L];
        while ((ax = arr.indexOf(what)) !== -1) {
            arr.splice(ax, 1);
        }
    }
    return arr;
}

const Days = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']

const QuoteService = [
    'Catering Service', 'Restaurant & Event Venue', 'Bakery & Caffe', 'Entertainment & Event Planning', 'Photography & videography', 'Flower & plant',
    'Beauty salon', 'Pet care', 'Auto Repair', 'Auto Dealership', 'Ride service', 'Delivery Service', 'Cleaning Service', 'Moving Companies & Storage facilities',
    'Duct cleaning', 'Power/Pressure washing', 'Dry cleaning and alternations', 'Care Services', 'Tutoring (Lesson) services', 'Counseling Services',
    'Estate Planning (Lawyer & Real Estate)', 'Financial & Accounting Services', 'Insurance', 'Real Estate (Apartment Rental)', 'Landscaping (Lawn Mow) Services', 'Remodeling',
    'Electrical Services', 'Plumbing', 'Heating, Ventilation & Air conditioning', 'Painting', 'Roofing', 'Concrete', 'Tree and Shrubs Services',
    'Locksmiths', 'Grocery', 'Home Maintenance store', 'Furniture Store', 'Wine Store', 'Shopping', 'Cell phone repair','Personal shopper','Handyman'
]
const BookAppointmentService = [
    'Beauty salon', 'Dentist', 'Pet Service', 'Massage', 'Physical training', 'Chiropractor', 'Physical Therapy', 'Auto Repair', 'Auto Detailing',
    'Housing (apartment, real estate or property management)', 'Lawyers', 'Business consulting', 'Cleaning services', 'Handyman', 'Wedding planner',
    'Construction & remodeling', 'Landscaping (lawn mows)', 'Electrical services', 'Plumbing', 'Heating, ventilation & air conditioning', 'Painting',
    'Roofing', 'Concrete', 'Tree & shrubs services','Junk removal and hauling','Furniture','Medical consultants','Home repair service','Boutiques(shopper)',
    'Cares','Lesson and tutoring','Moving company & Storage facility','Flower and plant','Counseling','Financing, accounting & taxation','Insurance', 'Locksmith'
];

class EditCouponrankingBusiness extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            firstName: '',
            formErrfirstName: '',
            lastName: '',
            formErrlastName: '',
            name: '',
            formErrname: '',
            business_owner_name: '',
            formErrbusiness_owner_name: '',
            country: '',
            formErrcountry: '',
            city: '',
            formErrcity: '',
            stateName: '',
            formErrstateName: '',
            country_code: '+1',
            formErrcountry_code: '',
            mobile_no: '',
            formErrmobile_no: '',
            email: '',
            formErremail: '',
            location: '',
            formErropen_time: '',
            formErrclose_time: '',
            formErrlocation: '',
            longitude: 23.53,
            latitude: 53.65,
            picture: '',
            formErrpicture: '',
            desc: '',
            formErrdesc: '',
            description: '',
            formErrdescription: '',
            // planList:[],
            file: '',
            productDetails: [{
                categoryName: '',
                productList: [
                    {
                        name: '',
                        price: 0,
                        status: ''
                    }
                ]
            }],
            categoryId: '',
            formErrcategoryId: '',
            subCategory: [],
            country_list: [],
            country_name: '',
            business_id: '',
            businessId: '',
            business_logo: '',
            oldBusinessLogo: '',
            formErrbusiness_logo: '',
            planType: '',
            oldPlanType: '',
            website: '',
            formErrwebsite: '',
            file1: '',
            perClickLimit: 0,
            monday_open_time_hour: '01',
            monday_open_time_min: '00',
            monday_open_time_am_pm: 'AM',
            monday_close_time_hour: '01',
            monday_close_time_min: '00',
            monday_close_time_am_pm: 'AM',

            is_monday_Open: false,

            tuesday_open_time_hour: '01',
            tuesday_open_time_min: '00',
            tuesday_open_time_am_pm: 'AM',
            tuesday_close_time_hour: '01',
            tuesday_close_time_min: '00',
            tuesday_close_time_am_pm: 'AM',

            is_tuesday_Open: false,

            wednesday_open_time_hour: '01',
            wednesday_open_time_min: '00',
            wednesday_open_time_am_pm: 'AM',
            wednesday_close_time_hour: '01',
            wednesday_close_time_min: '00',
            wednesday_close_time_am_pm: 'AM',

            is_wednesday_Open: false,

            thursday_open_time_hour: '01',
            thursday_open_time_min: '00',
            thursday_open_time_am_pm: 'AM',
            thursday_close_time_hour: '01',
            thursday_close_time_min: '00',
            thursday_close_time_am_pm: 'AM',

            is_thursday_Open: false,

            friday_open_time_hour: '01',
            friday_open_time_min: '00',
            friday_open_time_am_pm: 'AM',
            friday_close_time_hour: '01',
            friday_close_time_min: '00',
            friday_close_time_am_pm: 'AM',

            is_friday_Open: false,

            saturday_open_time_hour: '01',
            saturday_open_time_min: '00',
            saturday_open_time_am_pm: 'AM',
            saturday_close_time_hour: '01',
            saturday_close_time_min: '00',
            saturday_close_time_am_pm: 'AM',

            is_saturday_Open: false,
            sunday_open_time_hour: '01',
            sunday_open_time_min: '00',
            sunday_open_time_am_pm: 'AM',
            sunday_close_time_hour: '01',
            sunday_close_time_min: '00',
            sunday_close_time_am_pm: 'AM',
            is_sunday_Open: false,

            amenities: [],
            isEmailSelect: false,
            isCouponSelect: false,
            isPhoneSelect: false,
            isWebsiteSelect: false,
            isDirectionSelect: false,
            isDealSelect: false,
            isOrderNowSelect: false,
            isBookForAppointmentSelect: false,
            bookAppointmentService: '',
            formErrbookAppointmentService: '',
            isCallForQuoteSelect: false,
            requestQuoteService: '',
            formErrrequestQuoteService: '',
            isTextSelect: false,
            isEcommerceSelect: false,
            textMessage: '',
            dealText: '',
            couponText: ''

        }
        // this.handleUploadFile = this.handleUploadFile.bind(this)
        this.updateState = this.updateState.bind(this);
        // this.handleUploadFile1 = this.handleUploadFile1.bind(this)
        // this.handleEditorChange1 = this.handleEditorChange1.bind(this);
        // this.handleEditorChange2 = this.handleEditorChange2.bind(this);
        // this.handleEditorChange3 = this.handleEditorChange3.bind(this);
        this.addProductAndService = this.addProductAndService.bind(this)
    }

    componentWillMount() {
        this.props.fetchCategory()
        let businessId = this.props.match.params.id;
        this.props.getCouponrankingBusinessDetails({ businessId: businessId })
        this.props.advertiseSubscriptionList({ subscription_type: "monthly" })
    }
    componentDidUpdate(prevProps, prevState) {
        const { enqueueSnackbar, error, success, loading } = this.props;
        success && enqueueSnackbar(success, { variant: 'success' });
        error && enqueueSnackbar(error, { variant: 'error' });
        // loading && enqueueSnackbar (loading,{variant:'loading'})
    }

    updateState(data) {
        setTimeout(async () => {
            await this.setState(data)
        }, 1000)
    }

    getSubCategoryOnLoad = async (value) => {
        await this.props.fetchsubCategoryListAction(value)
    }
    getAmenities = async (value) => {
        await this.setState({ amenities: value })
    }

    onChangeAddName = async (key, value) => {
        var self = this;
        await self.setState({
            [key]: value,
            ['formErr' + key]: (value === '') ? 'Please provide this field' : ''
        })

        if (key === 'amenitisData') {
            let amenitisList = [...this.state.amenities]
            // console.log(key,value,'=========',amenitisList,this.state.amenities.includes(value))
            if (amenitisList.includes(value)) {
                let updatedlist = removeItems(amenitisList, value)
                this.setState({ amenities: updatedlist })
            } else {
                amenitisList.push(value)
                this.setState({ amenities: amenitisList })
            }
        }
        if (key === "category") {
            this.setState({ categoryId: value, subCategory: [] })
            this.props.fetchsubCategoryListAction(value)
        }
        if (key === "subcategoryDetails") {

            let subCategoryList = [...this.state.subCategory]
            let subDetails = { subCategoryId: value._id, subCategoryName: value.name }
            if (subCategoryList.length > 0 && subCategoryList.some(dd => dd.subCategoryId === value._id)) {
                let updatedlist = subCategoryList.filter(object => {
                    return object.subCategoryId !== value._id;
                });
                this.setState({ subCategory: updatedlist })
            } else {
                subCategoryList.push(subDetails)
                this.setState({ subCategory: subCategoryList })
            }
        }

    }

    addProductAndService = async () => {
        this.setState({
            isAddProduct: 1
        })
    }

    handlelatlong = (value) => {
        this.setState({
            latitude: value.latitude,
            longitude: value.longitude
        })
    }

    handleAddress = (value) => {
        this.setState({
            location: value
        })
    }
    handlepincode = (value) => {

    }
    handlecityname = (value) => {

    }

    handleUploadFile1 = async (event) => {
        await this.setState({
            file1: URL.createObjectURL(event.target.files[0]), business_logo: event.target.files[0],
            formErrbusiness_logo: ''
        })
    }
    handleSubmit = async () => {
        await this.setState({
            formErrname: (this.state.name === '') ? "Please provide this field" : '',
            formErrbusiness_owner_name: (this.state.business_owner_name == '') ? "Please provide this field" : '',
            formErrdescription: this.state.description === '' ? 'Please provide this field' : '',
            formErrcountry: (this.state.country === '') ? "Please provide this field" : '',
            formErrcity: (this.state.city === '') ? "Please provide this field" : '',
            formErrcountry_code: (this.state.country_code === '') ? "Please provide this field" : '',
            formErrmobile_no: (this.state.mobile_no === '') ? "Please provide this field" : '',
            formErremail: (this.state.email === '') ? "Please provide this field" : '',
            formErropen_time: (this.state.open_time === '') ? "Please provide this field" : '',
            formErrclose_time: (this.state.close_time === '') ? "Please provide this field" : '',
            formErrlocation: (this.state.location === '') ? "Please provide this field" : '',

            formErrserviceConnectFee: (this.state.serviceConnectFee === '') ? "Please provide this field" : '',
            formErrservicetax: (this.state.servicetax === '') ? "Please provide this field" : '',

            formErrtax: (this.state.tax === '') ? "Please provide this field" : '',
            formErrserviceFees: (this.state.serviceFees === '') ? "Please provide this field" : '',
            formErrprocessingFees: (this.state.processingFees === '') ? "Please provide processing and cent fees field also" : '',
            // formErrprocessingFees: ((this.state.processingFees.split("+")).length !== 2) ? "Please provide processing and cent fees field also" : '',
            formErrcentFees: (this.state.centFees === '') ? "Please provide processing and cent fees field also" : '',

            formErrhappyHourEndTime: (this.state.happyHourEndTime === '') ? "Please provide this field" : '',
            formErrhappyHourStartTime: (this.state.happyHourStartTime === '') ? "Please provide this field" : '',
            formErrhappyHourDiscount: (this.state.happyHourDiscount === '') ? "Please provide this field" : '',
            // formErrpaymentMethod: (this.state.paymentMethod === '') ? "Please provide this field" : '',
            formErrcurrency_symbol: (this.state.currency_symbol === '') ? "Please provide this field" : '',
            // formErrtip: (this.state.tip === '') ? "Please provide this field" : '',
            formErrcancellation_fees: (this.state.cancellation_fees === '') ? "Please provide this field" : '',


            formErrmin_charge: (this.state.ride_share !== 'none' && this.state.min_charge === '') ? "Please provide this field" : '',
            formErrper_mile_charge: (this.state.ride_share !== 'none' && this.state.per_mile_charge === '') ? "Please provide this field" : '',
            formErrper_min_charge: (this.state.ride_share !== 'none' && this.state.per_min_charge === '') ? "Please provide this field" : '',
            formErrmin: (this.state.ride_share !== 'none' && this.state.min === '') ? "Please provide this field" : '',
            formErrsec: (this.state.ride_share !== 'none' && this.state.sec === '') ? "Please provide this field" : '',
            formErrcents: (this.state.ride_share !== 'none' && this.state.cents === '') ? "Please provide this field" : '',

            formErradditional_charge: (this.state.ride_share !== 'none' && this.state.additional_charge === '') ? "Please provide this field" : '',
            formErrempty_driver_seat: (this.state.ride_share === 'ride_share' && this.state.empty_driver_seat === '') ? "Please provide this field" : '',

            formErrpicture: (this.state.ride_share !== 'none' && this.state.picture === '') ? "Please provide this field" : '',
            formErrdesc: (this.state.ride_share !== 'none' && this.state.desc === '') ? "Please provide this field" : '',
            formErrempty_driver_per_mile_charge: (this.state.ride_share !== 'none' && this.state.empty_driver_per_mile_charge === '') ? "Please provide this field" : '',

        })
        if (!((this.state.name === '') && (this.state.business_owner_name === '') && (this.state.country === '') && (this.state.city === '') && (this.state.country_code === '') && (this.state.mobile_no === '') && (this.state.email === '') && (this.state.description === '')
            (this.state.categoryId === '') && (this.state.isCallForQuoteSelect === '') && (this.state.requestQuoteService === ''))) {
            const data = new FormData();
            data.append('businessId', this.props.match.params.id)
            data.append('country_code', this.state.country_code);
            data.append('mobile_no', this.state.mobile_no);
            data.append('name', this.state.name);
            data.append('city', this.state.city);
            data.append('state', this.state.stateName);
            data.append('bussinessOwnerName', this.state.business_owner_name);
            data.append('email', this.state.email);
            data.append('country', this.state.country);
            data.append('businessDescription', this.state.description);
            data.append('productDetails', JSON.stringify(this.state.productDetails));
            data.append('amenities', JSON.stringify(this.state.amenities))
            if (this.state.oldPlanType !== this.state.planType) { data.append('planType', this.state.planType) }
            data.append('categoryId', this.state.categoryId)
            data.append('subCategory', JSON.stringify(this.state.subCategory))
            data.append('isEmailSelect', this.state.isEmailSelect)
            data.append('isCouponSelect', this.state.isCouponSelect)
            data.append('isPhoneSelect', this.state.isPhoneSelect)
            data.append('isWebsiteSelect', this.state.isWebsiteSelect)
            data.append('isDirectionSelect', this.state.isDirectionSelect)
            data.append('isDealSelect', this.state.isDealSelect)
            data.append('isOrderNowSelect', this.state.isOrderNowSelect)
            data.append('isBookForAppointmentSelect', this.state.isBookForAppointmentSelect)
            data.append('bookAppointmentService', this.state.bookAppointmentService)
            data.append('isCallForQuoteSelect', this.state.isCallForQuoteSelect)
            data.append('requestQuoteService', this.state.requestQuoteService)
            data.append('isTextSelect', this.state.isTextSelect)
            data.append('isEcommerceSelect', this.state.isEcommerceSelect)
            data.append('textMessage', this.state.textMessage)
            data.append('dealText', this.state.dealText)
            data.append('couponText', this.state.couponText)
            if (this.state.oldBusinessLogo !== this.state.business_logo) { data.append('business_logo', this.state.business_logo) }
            data.append('website', this.state.website);
            data.append('business_weekdays', JSON.stringify({
                monday: {
                    open_time_hour: this.state.monday_open_time_hour,
                    open_time_min: this.state.monday_open_time_min,
                    open_time_am_pm: this.state.monday_open_time_am_pm,
                    close_time_hour: this.state.monday_close_time_hour,
                    close_time_min: this.state.monday_close_time_min,
                    close_time_am_pm: this.state.monday_close_time_am_pm,
                },
                is_monday_Open: this.state.is_monday_Open,
                tuesday: {
                    open_time_hour: this.state.tuesday_open_time_hour,
                    open_time_min: this.state.tuesday_open_time_min,
                    open_time_am_pm: this.state.tuesday_open_time_am_pm,
                    close_time_hour: this.state.tuesday_close_time_hour,
                    close_time_min: this.state.tuesday_close_time_min,
                    close_time_am_pm: this.state.tuesday_close_time_am_pm,
                },
                is_tuesday_Open: this.state.is_tuesday_Open,
                wednesday: {
                    open_time_hour: this.state.wednesday_open_time_hour,
                    open_time_min: this.state.wednesday_open_time_min,
                    open_time_am_pm: this.state.wednesday_open_time_am_pm,
                    close_time_hour: this.state.wednesday_close_time_hour,
                    close_time_min: this.state.wednesday_close_time_min,
                    close_time_am_pm: this.state.wednesday_close_time_am_pm,
                },
                is_wednesday_Open: this.state.is_wednesday_Open,
                thursday: {
                    open_time_hour: this.state.thursday_open_time_hour,
                    open_time_min: this.state.thursday_open_time_min,
                    open_time_am_pm: this.state.thursday_open_time_am_pm,
                    close_time_hour: this.state.thursday_close_time_hour,
                    close_time_min: this.state.thursday_close_time_min,
                    close_time_am_pm: this.state.thursday_close_time_am_pm,
                },
                is_thursday_Open: this.state.is_thursday_Open,
                friday: {
                    open_time_hour: this.state.friday_open_time_hour,
                    open_time_min: this.state.friday_open_time_min,
                    open_time_am_pm: this.state.friday_open_time_am_pm,
                    close_time_hour: this.state.friday_close_time_hour,
                    close_time_min: this.state.friday_close_time_min,
                    close_time_am_pm: this.state.friday_close_time_am_pm,
                },
                is_friday_Open: this.state.is_friday_Open,

                saturday: {
                    open_time_hour: this.state.saturday_open_time_hour,
                    open_time_min: this.state.saturday_open_time_min,
                    open_time_am_pm: this.state.saturday_open_time_am_pm,
                    close_time_hour: this.state.saturday_close_time_hour,
                    close_time_min: this.state.saturday_close_time_min,
                    close_time_am_pm: this.state.saturday_close_time_am_pm,
                },
                is_saturday_Open: this.state.is_saturday_Open,
                sunday: {
                    open_time_hour: this.state.sunday_open_time_hour,
                    open_time_min: this.state.sunday_open_time_min,
                    open_time_am_pm: this.state.sunday_open_time_am_pm,
                    close_time_hour: this.state.sunday_close_time_hour,
                    close_time_min: this.state.sunday_close_time_min,
                    close_time_am_pm: this.state.sunday_close_time_am_pm,
                },
                is_sunday_Open: this.state.is_sunday_Open,
            }));
            data.append('local_city', this.state.location);
            data.append('latitude', this.state.latitude)
            data.append('longitude', this.state.longitude)
            data.append('perClickLimit', this.state.perClickLimit)
            data.append('address', this.state.location)
            this.props.editCouponRankingBusiness(data, this.props.history)

        }
    }
    handleChange({ index, element, i }) {
        element.preventDefault()
        let prodsD = [...this.state.productDetails]
        if (i !== undefined) {
            prodsD[i]["productList"][index][element.target.name] = element.target.value;
        } else {
            prodsD[i][element.target.name] = element.target.value;
        }
        this.setState({ productDetails: prodsD });
    }
    handleCategoryChange = (e, i) => {
        let prodsD = [...this.state.productDetails]
        if (i !== undefined) {
            prodsD[i].categoryName = e.target.value;
        } else {
            prodsD[i].categoryName = e.target.value;
        }
        this.setState({ productDetails: prodsD });
    }
    // handle click event of the Remove button
    handleRemoveClick = (index, i) => {
        let prodsD = [...this.state.productDetails]
        const list = [...prodsD[i].productList];
        list.splice(index, 1);
        prodsD[i].productList = list;
        this.setState({ productDetails: prodsD });
    };

    // handle click event of the Add button
    handleAddClick = (i) => {
        let proD = [...this.state.productDetails]
        let prodsD = [...this.state.productDetails[i].productList, { name: '', price: 0, status: 'none' }]
        proD[i].productList = prodsD;
        this.setState({ productDetails: proD });
    };

    // handle category click event of the Add category button
    handleCategoryAddClick = (i) => {
        let proD = [...this.state.productDetails]
        let prodsD = [...this.state.productDetails, {
            categoryName: '',
            productList: [
                {
                    name: '',
                    price: 0,
                    status: 'none'
                }
            ]
        }]
        proD = prodsD;
        this.setState({ productDetails: proD });
    };
    // handle category click event of the remove category button
    handleCategoryRemoveClick = (i) => {
        let prodsD = [...this.state.productDetails]
        prodsD.splice(i, 1);
        this.setState({ productDetails: prodsD });
    };
 
    render() {
        let getData = this.props.businessDetails
        if (Object.keys(getData).length !== 0 && !this.state.businessId && getData.amenities != undefined) {
            this.updateState({
                // {
                businessId: getData._id,
                country_code: getData.country_code,
                description: getData.businessDescription,
                mobile_no: getData.mobile_no,
                name: getData.name,
                city: getData.city,
                stateName: getData.state,
                business_owner_name: getData.business_owner_name,
                email: getData.email,
                country: getData.country,
                business_logo: getData.business_logo,
                oldBusinessLogo: getData.business_logo,
                website: getData.website,
                amenities: getData.amenities,
                file1: getData.business_logo,
                isEmailSelect: getData.isEmailSelect,
                isCouponSelect: getData.isCouponSelect,
                isPhoneSelect: getData.isPhoneSelect,
                isWebsiteSelect: getData.isWebsiteSelect,
                isDirectionSelect: getData.isDirectionSelect,
                isDealSelect: getData.isDealSelect,
                isOrderNowSelect: getData.isOrderNowSelect,
                isBookForAppointmentSelect: getData.isBookForAppointmentSelect,
                bookAppointmentService: getData.bookAppointmentService,
                isCallForQuoteSelect: getData.isCallForQuoteSelect,
                requestQuoteService: getData.requestQuoteService,
                isTextSelect: getData.isTextSelect,
                isEcommerceSelect: getData.isEcommerceSelect,
                textMessage: getData.textMessage,
                dealText: getData.dealText,
                couponText: getData.couponText,
                planType: getData.planType,
                oldPlanType: getData.planType,
                categoryId: getData.categoryId,
                productDetails: getData.productDetails,
                subCategory: getData.subCategory,
                perClickLimit: getData.perClickLimit,
                latitude: getData.latitude,
                longitude: getData.longitude,
                location: getData.address,

                monday_open_time_hour: (getData.business_weekdays == undefined) ? '' : getData.business_weekdays.monday.open_time_hour,
                monday_open_time_min: (getData.business_weekdays == undefined) ? '' : getData.business_weekdays.monday.open_time_min,
                monday_open_time_am_pm: (getData.business_weekdays == undefined) ? '' : getData.business_weekdays.monday.open_time_am_pm,
                monday_close_time_hour: (getData.business_weekdays == undefined) ? '' : getData.business_weekdays.monday.close_time_hour,
                monday_close_time_min: (getData.business_weekdays == undefined) ? '' : getData.business_weekdays.monday.close_time_min,
                monday_close_time_am_pm: (getData.business_weekdays == undefined) ? '' : getData.business_weekdays.monday.close_time_am_pm,

                is_monday_Open: (getData.business_weekdays == undefined) ? '' : getData.business_weekdays.is_monday_Open,

                tuesday_open_time_hour: (getData.business_weekdays == undefined) ? '' : getData.business_weekdays.tuesday.open_time_hour,
                tuesday_open_time_min: (getData.business_weekdays == undefined) ? '' : getData.business_weekdays.tuesday.open_time_min,
                tuesday_open_time_am_pm: (getData.business_weekdays == undefined) ? '' : getData.business_weekdays.tuesday.open_time_am_pm,
                tuesday_close_time_hour: (getData.business_weekdays == undefined) ? '' : getData.business_weekdays.tuesday.close_time_hour,
                tuesday_close_time_min: (getData.business_weekdays == undefined) ? '' : getData.business_weekdays.tuesday.close_time_min,
                tuesday_close_time_am_pm: (getData.business_weekdays == undefined) ? '' : getData.business_weekdays.tuesday.close_time_am_pm,

                is_tuesday_Open: (getData.business_weekdays == undefined) ? '' : getData.business_weekdays.is_tuesday_Open,

                wednesday_open_time_hour: (getData.business_weekdays == undefined) ? '' : getData.business_weekdays.wednesday.open_time_hour,
                wednesday_open_time_min: (getData.business_weekdays == undefined) ? '' : getData.business_weekdays.wednesday.open_time_min,
                wednesday_open_time_am_pm: (getData.business_weekdays == undefined) ? '' : getData.business_weekdays.wednesday.open_time_am_pm,
                wednesday_close_time_hour: (getData.business_weekdays == undefined) ? '' : getData.business_weekdays.wednesday.close_time_hour,
                wednesday_close_time_min: (getData.business_weekdays == undefined) ? '' : getData.business_weekdays.wednesday.close_time_min,
                wednesday_close_time_am_pm: (getData.business_weekdays == undefined) ? '' : getData.business_weekdays.wednesday.close_time_am_pm,

                is_wednesday_Open: (getData.business_weekdays == undefined) ? '' : getData.business_weekdays.is_wednesday_Open,

                thursday_open_time_hour: (getData.business_weekdays == undefined) ? '' : getData.business_weekdays.thursday.open_time_hour,
                thursday_open_time_min: (getData.business_weekdays == undefined) ? '' : getData.business_weekdays.thursday.open_time_min,
                thursday_open_time_am_pm: (getData.business_weekdays == undefined) ? '' : getData.business_weekdays.thursday.open_time_am_pm,
                thursday_close_time_hour: (getData.business_weekdays == undefined) ? '' : getData.business_weekdays.thursday.close_time_hour,
                thursday_close_time_min: (getData.business_weekdays == undefined) ? '' : getData.business_weekdays.thursday.close_time_min,
                thursday_close_time_am_pm: (getData.business_weekdays == undefined) ? '' : getData.business_weekdays.thursday.close_time_am_pm,

                is_thursday_Open: (getData.business_weekdays == undefined) ? '' : getData.business_weekdays.is_thursday_Open,

                friday_open_time_hour: (getData.business_weekdays == undefined) ? '' : getData.business_weekdays.friday.open_time_hour,
                friday_open_time_min: (getData.business_weekdays == undefined) ? '' : getData.business_weekdays.friday.open_time_min,
                friday_open_time_am_pm: (getData.business_weekdays == undefined) ? '' : getData.business_weekdays.friday.open_time_am_pm,
                friday_close_time_hour: (getData.business_weekdays == undefined) ? '' : getData.business_weekdays.friday.close_time_hour,
                friday_close_time_min: (getData.business_weekdays == undefined) ? '' : getData.business_weekdays.friday.close_time_min,
                friday_close_time_am_pm: (getData.business_weekdays == undefined) ? '' : getData.business_weekdays.friday.close_time_am_pm,

                is_friday_Open: (getData.business_weekdays == undefined) ? '' : getData.business_weekdays.is_friday_Open,

                saturday_open_time_hour: (getData.business_weekdays == undefined) ? '' : getData.business_weekdays.saturday.open_time_hour,
                saturday_open_time_min: (getData.business_weekdays == undefined) ? '' : getData.business_weekdays.saturday.open_time_min,
                saturday_open_time_am_pm: (getData.business_weekdays == undefined) ? '' : getData.business_weekdays.saturday.open_time_am_pm,
                saturday_close_time_hour: (getData.business_weekdays == undefined) ? '' : getData.business_weekdays.saturday.close_time_hour,
                saturday_close_time_min: (getData.business_weekdays == undefined) ? '' : getData.business_weekdays.saturday.close_time_min,
                saturday_close_time_am_pm: (getData.business_weekdays == undefined) ? '' : getData.business_weekdays.saturday.close_time_am_pm,

                is_saturday_Open: (getData.business_weekdays == undefined) ? '' : getData.business_weekdays.is_saturday_Open,
                sunday_open_time_hour: (getData.business_weekdays == undefined) ? '' : getData.business_weekdays.sunday.open_time_hour,
                sunday_open_time_min: (getData.business_weekdays == undefined) ? '' : getData.business_weekdays.sunday.open_time_min,
                sunday_open_time_am_pm: (getData.business_weekdays == undefined) ? '' : getData.business_weekdays.sunday.open_time_am_pm,
                sunday_close_time_hour: (getData.business_weekdays == undefined) ? '' : getData.business_weekdays.sunday.close_time_hour,
                sunday_close_time_min: (getData.business_weekdays == undefined) ? '' : getData.business_weekdays.sunday.close_time_min,
                sunday_close_time_am_pm: (getData.business_weekdays == undefined) ? '' : getData.business_weekdays.sunday.close_time_am_pm,

                is_sunday_Open: (getData.business_weekdays == undefined) ? '' : getData.business_weekdays.is_sunday_Open,

            });
        }

        if (this.props.subcategorylisting.length === 0) { this.getSubCategoryOnLoad(this.state.categoryId) }
        // if(this.state.amenities.length === 0){this.getAmenities(getData.amenities)}

        let prodDetails = this.state.productDetails;
        let details = this.props.subscriptionPlanlist ? this.props.subscriptionPlanlist : []
        var isPresent;
        var planList = [];
        var matchWords = ["(C &D)"];
        for (var index = 0; index < details.length; index++) {
            isPresent = true;
            for (var outer = 0; outer < matchWords.length; outer++) {
                if (details[index].advertisement_type.indexOf(matchWords[outer]) === -1) {
                    isPresent = false;
                    break;
                }
            }
            if (isPresent) {
                planList.push(details[index]);
            }
        }
        let dd = [{
            day: 'monday', status: this.state.is_monday_Open,
            openHrs: this.state.monday_open_time_hour, openMin: this.state.monday_open_time_min, open_am_pm: this.state.monday_open_time_am_pm,
            closeHrs: this.state.monday_close_time_hour, closeMin: this.state.monday_close_time_min, close_am_pm: this.state.monday_close_time_am_pm
        },
        {
            day: 'tuesday', status: this.state.is_tuesday_Open,
            openHrs: this.state.tuesday_open_time_hour, openMin: this.state.tuesday_open_time_min, open_am_pm: this.state.tuesday_open_time_am_pm,
            closeHrs: this.state.tuesday_close_time_hour, closeMin: this.state.tuesday_close_time_min, close_am_pm: this.state.tuesday_close_time_am_pm
        },
        {
            day: 'wednesday', status: this.state.is_wednesday_Open,
            openHrs: this.state.wednesday_open_time_hour, openMin: this.state.wednesday_open_time_min, open_am_pm: this.state.wednesday_open_time_am_pm,
            closeHrs: this.state.wednesday_close_time_hour, closeMin: this.state.wednesday_close_time_min, close_am_pm: this.state.wednesday_close_time_am_pm
        },
        {
            day: 'thursday', status: this.state.is_thursday_Open,
            openHrs: this.state.thursday_open_time_hour, openMin: this.state.thursday_open_time_min, open_am_pm: this.state.thursday_open_time_am_pm,
            closeHrs: this.state.thursday_close_time_hour, closeMin: this.state.thursday_close_time_min, close_am_pm: this.state.thursday_close_time_am_pm
        },
        {
            day: 'friday', status: this.state.is_friday_Open,
            openHrs: this.state.friday_open_time_hour, openMin: this.state.friday_open_time_min, open_am_pm: this.state.friday_open_time_am_pm,
            closeHrs: this.state.friday_close_time_hour, closeMin: this.state.friday_close_time_min, close_am_pm: this.state.friday_close_time_am_pm
        },
        {
            day: 'saturday', status: this.state.is_saturday_Open,
            openHrs: this.state.saturday_open_time_hour, openMin: this.state.saturday_open_time_min, open_am_pm: this.state.saturday_open_time_am_pm,
            closeHrs: this.state.saturday_close_time_hour, closeMin: this.state.saturday_close_time_min, close_am_pm: this.state.saturday_close_time_am_pm
        },
        {
            day: 'sunday', status: this.state.is_sunday_Open,
            openHrs: this.state.sunday_open_time_hour, openMin: this.state.sunday_open_time_min, open_am_pm: this.state.sunday_open_time_am_pm,
            closeHrs: this.state.sunday_close_time_hour, closeMin: this.state.sunday_close_time_min, close_am_pm: this.state.sunday_close_time_am_pm
        }
        ]
        return (
            <div>
                <div className="header bg-primary pb-6">
                    <div className="container-fluid">
                        <div className="header-body">
                            <div className="row align-items-center py-4">
                                <div className="col-lg-6 col-7">
                                    <h6 className="h2 text-white d-inline-block mb-0">
                                        Edit Coupon Ranking Details
                                    </h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container-fluid mt--6">
                    <div className="card">
                        <div className='card-body border-0'>
                            {/* All field Section */}
                            <div className="row">
                                <div className='col-sm-6'>
                                    <div className='form-group'>
                                        <label className='form-control-label' htmlFor='bOwnerName1'>Business Owner Name</label>
                                        <input type='text' id='bOwnerName1' className='form-control' placeholder='Enter First Name' required
                                            value={this.state.business_owner_name}
                                            onChange={(e) => this.onChangeAddName('business_owner_name', e.target.value)}
                                        />
                                        <span style={{ color: 'red' }}>{this.state.formErrfirstName}</span>
                                    </div>
                                </div>
                                <div className='col-sm-6'>
                                    <div className='form-group'>
                                        <label htmlFor='businessName' className='form-control-label'>Business Name</label>
                                        <input type='text' id='businessName' className='form-control' placeholder='Enter Business name' required
                                            value={this.state.name}
                                            onChange={(e) => this.onChangeAddName('name', e.target.value)}
                                        />
                                        <span style={{ color: 'red' }}>{this.state.formErrname}</span>
                                    </div>
                                </div>
                                <div className='col-sm-6'>
                                    <div className='form-group'>
                                        <label htmlFor='businessEmail' className='form-control-label'>Business Email</label>
                                        <input type='text' id='businessEmail' className='form-control' placeholder='Enter business email' required
                                            value={this.state.email}
                                            readOnly
                                        />
                                        <span style={{ color: 'red' }}>{this.state.formErremail}</span>
                                    </div>
                                </div>
                                <div className='col-sm-6'>
                                    <div className='form-group'>
                                        <label htmlFor='businessPhone' className='form-control-label'>Country Code</label>
                                        <PhoneInput
                                            country={'us'}
                                            value={this.state.country_code}
                                            // onChange={(e) => this.onChangeAddName('country_code', e.target.value)}
                                            onChange={(country_code) =>
                                                this.setState({
                                                    country_code: '+' + country_code,
                                                })
                                            }
                                        />
                                        <label htmlFor='businessPhone' className='form-control-label'>Business Phone</label>
                                        <input type='tel' id='businessPhone' className='form-control' placeholder='Enter business phone number' required
                                            value={this.state.mobile_no}
                                            onChange={(e) => this.onChangeAddName('mobile_no', e.target.value)}
                                        />
                                        <span style={{ color: 'red' }}>{this.state.formErrmobile_no}</span>
                                    </div>
                                </div>
                                <div className='col-sm-6'>
                                    <div className='form-group'>
                                        <label htmlFor='businessPhone' className='form-control-label'>Type of Business Plan Name</label>
                                        <select id="subscriptionPlan" class="form-control custom-select" required
                                            value={this.state.planType}
                                            type='select'
                                            onChange={(e) =>
                                                this.onChangeAddName(
                                                    'planType',
                                                    e.target.value
                                                )
                                            }
                                        >
                                            {planList.map((option) => (
                                                <option value={option._id}>
                                                    {option.advertisement_type}
                                                </option>
                                            ))}
                                        </select>
                                        <span style={{ color: 'red' }}></span>
                                    </div>
                                </div>
                            </div>

                            <div class="form-row">
                                <div class="form-group col-lg-3 col-sm-6">
                                    <label for="inputCountry" className="form-control-label">Country</label>
                                    <input type="text" class="form-control" id="inputCountry" placeholder="Enter your country" required
                                        value={this.state.country}
                                        onChange={(e) => this.onChangeAddName('country', e.target.value)}
                                    />
                                    <span style={{ color: 'red' }}>{this.state.formErrcountry}</span>
                                </div>
                                <div class="form-group col-lg-3 col-sm-6">
                                    <label for="inputState" className="form-control-label">State</label>
                                    <input type="text" class="form-control" id="inputState" placeholder="Enter your state" required
                                        value={this.state.stateName}
                                        onChange={(e) => this.onChangeAddName('stateName', e.target.value)}
                                    />
                                    <span style={{ color: 'red' }}>{this.state.formErrstate}</span>
                                </div>
                                <div class="form-group col-lg-3 col-sm-6">
                                    <label for="inputCity" className="form-control-label">City</label>
                                    <input type="text" class="form-control" id="inputCity" placeholder="Enter your city" required
                                        value={this.state.city}
                                        onChange={(e) => this.onChangeAddName('city', e.target.value)}
                                    />
                                    <span style={{ color: 'red' }}>{this.state.formErrcity}</span>
                                </div>
                                <div class="form-group col-lg-3 col-sm-6">
                                    <label for="inputCategory" className="form-control-label">Category</label>
                                    <select id="inputCategory" class="form-control custom-select" required
                                        value={this.state.categoryId}
                                        type='select'
                                        onChange={(e) =>
                                            this.onChangeAddName(
                                                'category',
                                                e.target.value
                                            )
                                        }
                                    >
                                        {this.props.categorylisting.map((option) => (
                                            <option value={option._id}>
                                                {option.name}
                                            </option>
                                        ))}
                                    </select>
                                    <span style={{ color: 'red' }}>{this.state.formErrcategory}</span>
                                </div>
                            </div>

                            {/* subcategory listing */}
                            {this.props.subcategorylisting.length > 0 ?
                                <div className="form-row mb-3" >
                                    <h5 className="col-12">Select subcategory</h5>
                                    {this.props.subcategorylisting ?
                                        this.props.subcategorylisting.map((ele) => (

                                            <div className="col-md-6">
                                                <div class="custom-control custom-checkbox pl-3 mr-4 mr-lg-5 my-1">
                                                    <input class="custom-control-input" type="checkbox" id={ele._id}
                                                        checked={this.state.subCategory.some(dd => dd.subCategoryId === ele._id) ? true : false}
                                                        onChange={(e) =>
                                                            this.onChangeAddName(
                                                                'subcategoryDetails',
                                                                ele
                                                            )
                                                        }
                                                    />
                                                    <label class="custom-control-label ml-3" for={ele._id} style={{ paddingTop: '2px', height: 'auto' }}>{ele.name}</label>
                                                </div>
                                            </div>
                                        ))
                                        : ''
                                    }
                                </div> : ''
                            }

                            {/* Business Description Section */}
                            <div className='business-desc'>
                                <div className='form-group'>
                                    <label htmlFor='bDescription' className='form-control-label'>Business Description (About us)</label>
                                    <textarea id='bDescription' rows='4' className='form-control' placeholder='A few words about you ...'
                                        value={this.state.description}
                                        onChange={(e) => this.onChangeAddName('description', e.target.value)}
                                    ></textarea>
                                </div>
                            </div>

                            {/* Service Provider Section */}
                            <div className='srvc-provider-sec mb-3'>
                                <h2 className="sec-title">List of services provider</h2>
                                {
                                    prodDetails.map((e, i) => (

                                        <div className="repeat-with-category">
                                            <div class="form-group mb-2">
                                                <label for="serviceName" className="form-control-label">Product/Service Category Name</label>
                                                <input type="text" class="form-control" id="serviceName" placeholder="Enter service or product name"
                                                    name="categoryName" value={e.categoryName}
                                                    onChange={(e) => this.handleCategoryChange(e, i)}
                                                />
                                            </div>
                                            {

                                                e.productList.length > 0 && e.productList.map((element, index) => (
                                                    <div class="form-row">
                                                        {/* {console.log(element,'==========')} */}
                                                        <div class="form-group col-md-4 mb-2">
                                                            <label for="serviceName" className="form-control-label">Name</label>
                                                            <input type="text" class="form-control" id="serviceName" placeholder="Enter service or product name" required
                                                                name="name" value={element.name}
                                                                onChange={element => this.handleChange({ index, element, i })}
                                                            />
                                                        </div>
                                                        <div class="form-group form-group col-md-3 mb-2">
                                                            <label for="servicePrice" className="form-control-label">Price ($)</label>
                                                            <input type="number" min={0} class="form-control" id="servicePrice" placeholder="Enter price" required
                                                                name="price" value={element.price}
                                                                onChange={element => this.handleChange({ index, element, i })}
                                                            />
                                                        </div>
                                                        <div class="form-group form-group col-md-3 mb-2">

                                                            <label for="servicePrice" className="form-control-label">Status</label>
                                                            {/* <input type="text" class="form-control" id="serviceStatus" placeholder="Enter status" required
                                                                name="status" value={element.status}
                                                                onChange={element => this.handleChange({ index, element, i })}
                                                            /> */}
                                                            <select id="inputCategory" class="form-control custom-select" name="status"
                                                                value={element.status}
                                                                type='select'
                                                                onChange={element => this.handleChange({ index, element, i })}
                                                            >
                                                                {['no_price', 'show_price', 'per_hour', 'per_day', 'none', 'call_for_quotes'].map((option) => (
                                                                    <option value={option}>
                                                                        {option}
                                                                    </option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                        {e.productList.length - 1 === index ?
                                                            <div class="form-group col-md-2 mb-2">
                                                                <label for="servicePrice" className="form-control-label">&nbsp;</label>
                                                                <div className="add-more">
                                                                    <Button variant="contained" component="label" size="large" className="text-capitalize" onClick={() => this.handleAddClick(i)}><i class="fas fa-plus mr-1"></i>Add</Button>
                                                                </div>
                                                            </div> :
                                                            <div class="form-group col-md-2 mb-2">
                                                                <label for="servicePrice" className="form-control-label">&nbsp;</label>
                                                                <div className="add-more">
                                                                    <Button variant="contained" component="label" size="large" style={{ backgroundColor: '#c03535' }} className="text-capitalize" onClick={(index) => this.handleRemoveClick(index, i)}><i class="fas fa-minus mr-1"></i>Remove</Button>
                                                                </div>
                                                            </div>
                                                        }
                                                    </div>
                                                ))}
                                            {
                                                prodDetails.length - 1 == i ?
                                                    <div className="btn-wrapper mt-3">
                                                        <Button variant="contained" component="label" size="large" className="text-capitalize" onClick={() => this.handleCategoryAddClick(i)} ><i class="fas fa-plus mr-1"></i>Add with category</Button>
                                                    </div> :
                                                    <div className="btn-wrapper mt-3">
                                                        <Button variant="contained" component="label" size="large" className="text-capitalize" style={{ backgroundColor: '#c03535' }} onClick={() => this.handleCategoryRemoveClick(i)} ><i class="fas fa-minus mr-1"></i>Remove with category</Button>
                                                    </div>
                                            }

                                        </div>
                                    ))
                                }
                            </div>

                            {/* Upload Logo & Photo Section */}
                            <div className='logo-photo-sec mb-4'>
                                <h2 className="sec-title">Upload logo</h2>
                                <div className='row'>
                                    {/* {[...Array(4)].map((elementInArray, index) => ( */}
                                    <div className='col-sm-6 col-md-3'>
                                        <div className='uploaded-img-wrapper mb-3'>
                                            <img src={this.state.business_logo === '' ? '../assets/img/theme/img-1-1000x600.jpg' : this.state.file1} alt="" />
                                        </div>
                                    </div>
                                    {/* ))} */}

                                    <div className='col-md-12'>
                                        <Button variant="contained" component="label" size="large" className="text-capitalize"><i class="fas fa-upload mr-1" ></i>Upload
                                            <input hidden accept="image/*" multiple type="file" onChange={this.handleUploadFile1} />
                                        </Button>
                                    </div>
                                </div>
                            </div>

                            <div className='form-group'>
                                <label className='form-control-label' htmlFor='bWebsite'>Business Website</label>
                                <input type='url' id='bWebsite' className='form-control' placeholder='Enter website' required
                                    value={this.state.website}
                                    onChange={(e) => this.onChangeAddName('website', e.target.value)}
                                />
                                <span style={{ color: 'red' }}></span>
                            </div>
                            <div className='form-group'>
                                <label className='form-control-label' htmlFor='bWebsite'>Per Click Limit</label>
                                <input type='number' min={0} id='bWebsite' className='form-control' placeholder='Per click Number' required
                                    value={this.state.perClickLimit}
                                    onChange={(e) => this.onChangeAddName('perClickLimit', e.target.value)}
                                />
                                <span style={{ color: 'red' }}></span>
                            </div>

                            {/* Location Section */}
                            <div className='location-sec mb-4'>
                                <h2 className="sec-title">Business Location</h2>
                                <div className="location-wrapper">
                                    <label className='form-control-label' for='input-first-name'>Business Location</label>
                                    <input type='text' id='input-first-name' className='form-control' placeholder='Business Location'
                                        value={this.state.location} onChange={(e) => this.onChangeAddName('location', e.target.value)}
                                    />
                                    {/* <span style={{ color: 'red' }}>{this.state.formErrlocation}</span> */}
                                    {/* </div> */}
                                    <div
                                        style={{
                                            height: '500px',
                                            width: '500px'
                                        }}
                                    >
                                        <Map
                                            center={{ lat: this.state.latitude, lng: this.state.longitude }}
                                            handlelatlong={(e) => this.handlelatlong(e)}
                                            handleAddress={(e) => this.handleAddress(e)}
                                            handlepincode={(e) => this.handlepincode(e)}
                                            handlecityname={(e) => this.handlecityname(e)}
                                            height='400px'
                                            value={this.state.location}
                                            // onChange={(e) => this.onChangeAddName('location', e.target.value)}
                                            zoom={15}
                                        />
                                    </div>
                                    <span style={{ color: 'red' }}>{this.state.formErrlocation}</span>
                                </div>
                            </div>

                            {/* Hour's of Operation Section */}
                            <div className="hour-operation-sec">
                                <h2 className="sec-title">Hour's of operation</h2>
                                {/* Days Status */}
                                {dd.map((name, index) => (
                                    <>
                                        <div class="custom-control custom-switch" style={{ marginLeft: '28px' }}>
                                            <input type="checkbox" class="custom-control-input" id={`customSwitch${index}`} checked={name.status}
                                                onChange={(e) =>
                                                    this.onChangeAddName(
                                                        `is_${name.day}_Open`,
                                                        name.status ? false : true
                                                    )
                                                }
                                            />
                                            <label class="custom-control-label text-primary" for={`customSwitch${index}`} style={{ fontWeight: '600' }}>{name.day}</label>
                                        </div>

                                        <div className="form-row" key={index}>
                                            <div class="col-lg-2 col-4">
                                                <div class="form-group">
                                                    <label htmlFor="" class="form-control-label">Open hour</label>
                                                    <select id={name.day} class="form-control custom-select" value={name.openHrs}
                                                        onChange={(e) =>
                                                            this.onChangeAddName(
                                                                `${name.day}_open_time_hour`,
                                                                e.target.value
                                                            )
                                                        }
                                                    >
                                                        {hours.map((option) => (
                                                            <option value={option}>{option}</option>
                                                        ))}
                                                    </select>
                                                </div>
                                            </div>
                                            <div class="col-lg-2 col-4">
                                                <div class="form-group">
                                                    <label htmlFor="" class="form-control-label">Open min</label>
                                                    <select id={name.day} class="form-control custom-select" value={name.openMin}
                                                        onChange={(e) =>
                                                            this.onChangeAddName(
                                                                `${name.day}_open_time_min`,
                                                                e.target.value
                                                            )
                                                        }
                                                    >
                                                        {minutes.map((option) => (
                                                            <option value={option}>{option}</option>
                                                        ))}
                                                    </select>
                                                </div>
                                            </div>
                                            <div class="col-lg-2 col-4">
                                                <div class="form-group">
                                                    <label htmlFor="" class="form-control-label" for="input-first-name">Open am/pm</label>
                                                    <select id={name.day} class="form-control custom-select" value={name.open_am_pm}
                                                        onChange={(e) =>
                                                            this.onChangeAddName(
                                                                `${name.day}_open_time_am_pm`,
                                                                e.target.value
                                                            )
                                                        }
                                                    >
                                                        <option value="AM">AM</option>
                                                        <option value="PM">PM</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div class="col-lg-2 col-4">
                                                <div class="form-group">
                                                    <label htmlFor="" class="form-control-label" for="input-first-name">{name.day} close hour</label>
                                                    <select id="" class="form-control custom-select" value={name.closeHrs}
                                                        onChange={(e) =>
                                                            this.onChangeAddName(
                                                                `${name.day}_close_time_hour`,
                                                                e.target.value
                                                            )
                                                        }
                                                    >
                                                        {hours.map((option) => (
                                                            <option value={option}>{option}</option>
                                                        ))}
                                                    </select>
                                                </div>
                                            </div>
                                            <div class="col-lg-2 col-4">
                                                <div class="form-group">
                                                    <label htmlFor="" class="form-control-label" for="input-first-name">Close min</label>
                                                    <select id="" class="form-control custom-select" value={name.closeMin}
                                                        onChange={(e) =>
                                                            this.onChangeAddName(
                                                                `${name.day}_close_time_min`,
                                                                e.target.value
                                                            )
                                                        }
                                                    >
                                                        {minutes.map((option) => (
                                                            <option value={option}>{option}</option>
                                                        ))}
                                                    </select>
                                                </div>
                                            </div>
                                            <div class="col-lg-2 col-4">
                                                <div class="form-group">
                                                    <label htmlFor="" class="form-control-label" for="input-first-name">Close am/pm</label>
                                                    <select id="" class="form-control custom-select" value={name.close_am_pm}
                                                        onChange={(e) =>
                                                            this.onChangeAddName(
                                                                `${name.day}_close_time_am_pm`,
                                                                e.target.value
                                                            )
                                                        }
                                                    >
                                                        <option value="AM">AM</option>
                                                        <option value="PM">PM</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                ))}
                            </div>

                            {/* Amenities & More Section */}

                            <div className='amenities-sec'>
                                <h2 className="sec-title">Amenities & more</h2>
                                <div className='checkbox-wrapper d-flex flex-column flex-md-row flex-wrap align-items-md-center mb-3'>
                                    <div class="custom-control custom-checkbox pl-3 mr-4 mr-lg-5 my-1">
                                        <input class="custom-control-input" type="checkbox" id="inlineCheckbox1" value={this.state.amenities.includes('Offer Takeout') ? true : false}
                                            onChange={(e) =>
                                                this.onChangeAddName(
                                                    'amenitisData',
                                                    'Offer Takeout'
                                                )
                                            }
                                            checked={this.state.amenities.includes('Offer Takeout') ? true : false}
                                        />
                                        <label class="custom-control-label ml-3" for="inlineCheckbox1" style={{ paddingTop: '2px' }}><i class="fa fa-plus" aria-hidden="true"></i> Offer Takeout</label>
                                    </div>
                                    <div class="custom-control custom-checkbox pl-3 mr-4 mr-lg-5 my-1">
                                        <input class="custom-control-input" type="checkbox" id="inlineCheckbox2" value={this.state.amenities.includes('Offer Catering') ? true : false}
                                            onChange={(e) =>
                                                this.onChangeAddName(
                                                    'amenitisData',
                                                    'Offer Catering'
                                                )
                                            }
                                            checked={this.state.amenities.includes('Offer Catering') ? true : false}
                                        />
                                        <label class="custom-control-label ml-3" for="inlineCheckbox2" style={{ paddingTop: '2px' }}><i class="fas fa-hamburger"></i> Offer Catering</label>
                                    </div>
                                    <div class="custom-control custom-checkbox pl-3 mr-4 mr-lg-5 my-1">
                                        <input class="custom-control-input" type="checkbox" id="inlineCheckbox3" value={this.state.amenities.includes('Good & Kids') ? true : false}
                                            onChange={(e) =>
                                                this.onChangeAddName(
                                                    'amenitisData',
                                                    'Good & Kids'
                                                )
                                            }
                                            checked={this.state.amenities.includes('Good & Kids') ? true : false}
                                        />
                                        <label class="custom-control-label ml-3" for="inlineCheckbox3" style={{ paddingTop: '2px' }}><i class="far fa-paper-plane"></i> Good & Kids</label>
                                    </div>
                                    <div class="custom-control custom-checkbox pl-3 mr-4 mr-lg-5 my-1">
                                        <input class="custom-control-input" type="checkbox" id="inlineCheckbox4" value={this.state.amenities.includes('Free wi-fi') ? true : false}
                                            onChange={(e) =>
                                                this.onChangeAddName(
                                                    'amenitisData',
                                                    'Free wi-fi'
                                                )
                                            }
                                            checked={this.state.amenities.includes('Free wi-fi') ? true : false}
                                        />
                                        <label class="custom-control-label ml-3" for="inlineCheckbox4" style={{ paddingTop: '2px' }}><i class="fa fa-wifi" aria-hidden="true"></i> Free Wi-Fi</label>
                                    </div>
                                    <div class="custom-control custom-checkbox pl-3 mr-4 mr-lg-5 my-1">
                                        <input class="custom-control-input" type="checkbox" id="inlineCheckbox5" value={this.state.amenities.includes('TV') ? true : false}
                                            onChange={(e) =>
                                                this.onChangeAddName(
                                                    'amenitisData',
                                                    'TV'
                                                )
                                            }
                                            checked={this.state.amenities.includes('TV') ? true : false} />
                                        <label class="custom-control-label ml-3" for="inlineCheckbox5" style={{ paddingTop: '2px' }}><i class="fas fa-tv"></i> TV</label>
                                    </div>
                                    <div class="custom-control custom-checkbox pl-3 mr-4 mr-lg-5 my-1">
                                        <input class="custom-control-input" type="checkbox" id="inlineCheckbox6" value={this.state.amenities.includes('Accept Credit Card') ? true : false}
                                            onChange={(e) =>
                                                this.onChangeAddName(
                                                    'amenitisData',
                                                    'Accept Credit Card'
                                                )
                                            }
                                            checked={this.state.amenities.includes('Accept Credit Card') ? true : false} />
                                        <label class="custom-control-label ml-3" for="inlineCheckbox6" style={{ paddingTop: '2px' }}><i class="fas fa-credit-card"></i> Accept Credit Card</label>
                                    </div>
                                    <div class="custom-control custom-checkbox pl-3 mr-4 mr-lg-5 my-1">
                                        <input class="custom-control-input" type="checkbox" id="inlineCheckbox7" value={this.state.amenities.includes('Waiter Service') ? true : false}
                                            onChange={(e) =>
                                                this.onChangeAddName(
                                                    'amenitisData',
                                                    'Waiter Service'
                                                )
                                            }
                                            checked={this.state.amenities.includes('Waiter Service') ? true : false} />
                                        <label class="custom-control-label ml-3" for="inlineCheckbox7" style={{ paddingTop: '2px' }}><i class="fas fa-male"></i> Waiter Service</label>
                                    </div>
                                    <div class="custom-control custom-checkbox pl-3 mr-4 mr-lg-5 my-1">
                                        <input class="custom-control-input" type="checkbox" id="inlineCheckbox8" value={this.state.amenities.includes('Healt Score Green') ? true : false}
                                            onChange={(e) =>
                                                this.onChangeAddName(
                                                    'amenitisData',
                                                    'Healt Score Green'
                                                )
                                            }
                                            checked={this.state.amenities.includes('Healt Score Green') ? true : false} />
                                        <label class="custom-control-label ml-3" for="inlineCheckbox8" style={{ paddingTop: '2px' }}><i class="fas fa-notes-medical"></i> Health Score Green</label>
                                    </div>
                                    <div class="custom-control custom-checkbox pl-3 mr-4 mr-lg-5 my-1">
                                        <input class="custom-control-input" type="checkbox" id="inlineCheckbox9" value={this.state.amenities.includes('No Alchohal') ? true : false}
                                            onChange={(e) =>
                                                this.onChangeAddName(
                                                    'amenitisData',
                                                    'No Alchohal'
                                                )
                                            }
                                            checked={this.state.amenities.includes('No Alchohal') ? true : false}
                                        />
                                        <label class="custom-control-label ml-3" for="inlineCheckbox9" style={{ paddingTop: '2px' }}><i class="fas fa-ban"></i> No Alcohal</label>
                                    </div>
                                    <div class="custom-control custom-checkbox pl-3 mr-4 mr-lg-5 my-1">
                                        <input class="custom-control-input" type="checkbox" id="inlineCheckbox10" value={this.state.amenities.includes('Offer Delivery') ? true : false}
                                            onChange={(e) =>
                                                this.onChangeAddName(
                                                    'amenitisData',
                                                    'Offer Delivery'
                                                )
                                            }
                                            checked={this.state.amenities.includes('Offer Delivery') ? true : false}
                                        />
                                        <label class="custom-control-label ml-3" for="inlineCheckbox10" style={{ paddingTop: '2px' }}><i class="fas fa-truck"></i> Offer Delivery</label>
                                    </div>
                                    <div class="custom-control custom-checkbox pl-3 mr-4 mr-lg-5 my-1">
                                        <input class="custom-control-input" type="checkbox" id="inlineCheckbox11" value={this.state.amenities.includes('Accept Apple Play') ? true : false}
                                            onChange={(e) =>
                                                this.onChangeAddName(
                                                    'amenitisData',
                                                    'Accept Apple Play'
                                                )
                                            }
                                            checked={this.state.amenities.includes('Accept Apple Play') ? true : false
                                            }
                                        />
                                        <label class="custom-control-label ml-3" for="inlineCheckbox11" style={{ paddingTop: '2px' }}><i class="fab fa-apple-pay"></i> Accept Apple Play</label>
                                    </div>
                                    <div class="custom-control custom-checkbox pl-3 mr-4 mr-lg-5 my-1">
                                        <input class="custom-control-input" type="checkbox" id="inlineCheckbox12" value={this.state.amenities.includes('Wheel Chair Accessible') ? true : false}
                                            onChange={(e) =>
                                                this.onChangeAddName(
                                                    'amenitisData',
                                                    'Wheel Chair Accessible'
                                                )
                                            }
                                            checked={this.state.amenities.includes('Wheel Chair Accessible') ? true : false}
                                        />
                                        <label class="custom-control-label ml-3" for="inlineCheckbox12" style={{ paddingTop: '2px' }}><i class="fas fa-wheelchair"></i> Wheel chair Accesible</label>
                                    </div>
                                    <div class="custom-control custom-checkbox pl-3 mr-4 mr-lg-5 my-1">
                                        <input class="custom-control-input" type="checkbox" id="inlineCheckbox13" value={this.state.amenities.includes('Private lot Parking') ? true : false}
                                            onChange={(e) =>
                                                this.onChangeAddName(
                                                    'amenitisData',
                                                    'Private lot Parking'
                                                )
                                            }
                                            checked={this.state.amenities.includes('Private lot Parking') ? true : false}
                                        />
                                        <label class="custom-control-label ml-3" for="inlineCheckbox13" style={{ paddingTop: '2px' }}><i class="fas fa-parking"></i> Private Lot Parking</label>
                                    </div>
                                    <div class="custom-control custom-checkbox pl-3 mr-4 mr-lg-5 my-1">
                                        <input class="custom-control-input" type="checkbox" id="inlineCheckbox14" value={this.state.amenities.includes('Bike Parking') ? true : false}
                                            onChange={(e) =>
                                                this.onChangeAddName(
                                                    'amenitisData',
                                                    'Bike Parking'
                                                )
                                            }
                                            checked={this.state.amenities.includes('Bike Parking') ? true : false}
                                        />
                                        <label class="custom-control-label ml-3" for="inlineCheckbox14" style={{ paddingTop: '2px' }}><i class="fas fa-parking"></i> Bike Parking</label>
                                    </div>
                                    <div class="custom-control custom-checkbox pl-3 mr-4 mr-lg-5 my-1">
                                        <input class="custom-control-input" type="checkbox" id="inlineCheckbox15" value={this.state.amenities.includes('Virtual Constituation') ? true : false}
                                            onChange={(e) =>
                                                this.onChangeAddName(
                                                    'amenitisData',
                                                    'Virtual Constituation'
                                                )
                                            }
                                            checked={this.state.amenities.includes('Virtual Constituation') ? true : false}
                                        />
                                        <label class="custom-control-label ml-3" for="inlineCheckbox15" style={{ paddingTop: '2px' }}><i class="fas fa-vr-cardboard"></i> Virtual Constituation</label>
                                    </div>
                                    <div class="custom-control custom-checkbox pl-3 mr-4 mr-lg-5 my-1">
                                        <input class="custom-control-input" type="checkbox" id="inlineCheckbox16" value={this.state.amenities.includes('Staff wear Mask') ? true : false}
                                            onChange={(e) =>
                                                this.onChangeAddName(
                                                    'amenitisData',
                                                    'Staff wear Mask'
                                                )
                                            }
                                            checked={this.state.amenities.includes('Staff wear Mask') ? true : false}
                                        />
                                        <label class="custom-control-label ml-3" for="inlineCheckbox16" style={{ paddingTop: '2px' }}><i class="fas fa-mask"></i> Staff Wear Mask</label>
                                    </div>
                                    <div class="custom-control custom-checkbox pl-3 mr-4 mr-lg-5 my-1">
                                        <input class="custom-control-input" type="checkbox" id="inlineCheckbox17" value={this.state.amenities.includes('Good for Kids') ? true : false}
                                            onChange={(e) =>
                                                this.onChangeAddName(
                                                    'amenitisData',
                                                    'Good for Kids'
                                                )
                                            }
                                            checked={this.state.amenities.includes('Good for Kids') ? true : false}
                                        />
                                        <label class="custom-control-label ml-3" for="inlineCheckbox17" style={{ paddingTop: '2px' }}><i class="fas fa-child"></i> Good for Kids</label>
                                    </div>
                                    <div class="custom-control custom-checkbox pl-3 mr-4 mr-lg-5 my-1">
                                        <input class="custom-control-input" type="checkbox" id="inlineCheckbox18" value={this.state.amenities.includes('Moderate Noise') ? true : false}
                                            onChange={(e) =>
                                                this.onChangeAddName(
                                                    'amenitisData',
                                                    'Moderate Noise'
                                                )
                                            }
                                            checked={this.state.amenities.includes('Moderate Noise') ? true : false}
                                        />
                                        <label class="custom-control-label ml-3" for="inlineCheckbox18" style={{ paddingTop: '2px' }}><i class="fas fa-volume-down"></i> Moderate Noise</label>
                                    </div>
                                    <div class="custom-control custom-checkbox pl-3 mr-4 mr-lg-5 my-1">
                                        <input class="custom-control-input" type="checkbox" id="inlineCheckbox19" value={this.state.amenities.includes('Reservation Available') ? true : false}
                                            onChange={(e) =>
                                                this.onChangeAddName(
                                                    'amenitisData',
                                                    'Reservation Available'
                                                )
                                            }
                                            checked={this.state.amenities.includes('Reservation Available') ? true : false}
                                        />
                                        <label class="custom-control-label ml-3" for="inlineCheckbox19" style={{ paddingTop: '2px' }}><i class="fas fa-chair"></i> Reservation Available</label>
                                    </div>
                                </div>
                                <hr />
                                <div className='checkbox-wrapper'>
                                    <div className="row d-flex align-items-center mb-3">
                                        <div className="col-xl-2 col-md-3 col-4">
                                            <div className="custom-control custom-checkbox">
                                                <input className="custom-control-input" type="checkbox" value={this.state.isEmailSelect} id="emailCheck"
                                                    onChange={(e) =>
                                                        this.onChangeAddName(
                                                            'isEmailSelect',
                                                            this.state.isEmailSelect ? false : true
                                                        )
                                                    }
                                                    checked={this.state.isEmailSelect ? true : false}
                                                />
                                                <label className="custom-control-label" htmlFor="emailCheck" style={{ paddingTop: '2px' }}>Email</label>
                                            </div>
                                        </div>
                                        <div className="col-xl-10 col-md-9 col-8">
                                            <input type='email' className='form-control' placeholder={this.state.isEmailSelect ? this.state.email : ''} readOnly />
                                        </div>
                                    </div>
                                    <div className="row d-flex align-items-center mb-3">
                                        <div className="col-xl-2 col-md-3 col-4">
                                            <div className="custom-control custom-checkbox">
                                                <input className="custom-control-input" type="checkbox" value={this.state.isPhoneSelect} id="phoneCheck"
                                                    onChange={(e) =>
                                                        this.onChangeAddName(
                                                            'isPhoneSelect',
                                                            this.state.isPhoneSelect ? false : true
                                                        )
                                                    }
                                                    checked={this.state.isPhoneSelect ? true : false}
                                                />
                                                <label className="custom-control-label" htmlFor="phoneCheck" style={{ paddingTop: '2px' }}>Phone Number</label>
                                            </div>
                                        </div>
                                        <div className="col-xl-10 col-md-9 col-8">
                                            <input type='number' className='form-control' placeholder={this.state.isPhoneSelect ? this.state.country_code + this.state.mobile_no : ''} readOnly />
                                        </div>
                                    </div>
                                    <div className="row d-flex align-items-center mb-3">
                                        <div className="col-xl-2 col-md-3 col-4">
                                            <div className="custom-control custom-checkbox">
                                                <input className="custom-control-input" type="checkbox" value={this.state.isWebsiteSelect} id="websiteCheck"
                                                    onChange={(e) =>
                                                        this.onChangeAddName(
                                                            'isWebsiteSelect',
                                                            this.state.isWebsiteSelect ? false : true
                                                        )
                                                    }
                                                    checked={this.state.isWebsiteSelect ? true : false}
                                                />
                                                <label className="custom-control-label" htmlFor="websiteCheck" style={{ paddingTop: '2px' }}>Website</label>
                                            </div>
                                        </div>
                                        <div className="col-xl-10 col-md-9 col-8">
                                            <input type='url' className='form-control' placeholder={this.state.isWebsiteSelect ? this.state.website : ''} readOnly />
                                        </div>
                                    </div>
                                    <div className="row d-flex align-items-center mb-3">
                                        <div className="col-xl-2 col-md-3 col-4">
                                            <div className="custom-control custom-checkbox">
                                                <input className="custom-control-input" type="checkbox" value={this.state.isDirectionSelect} id="directionCheck"
                                                    onChange={(e) =>
                                                        this.onChangeAddName(
                                                            'isDirectionSelect',
                                                            this.state.isDirectionSelect ? false : true
                                                        )
                                                    }
                                                    checked={this.state.isDirectionSelect ? true : false}
                                                />
                                                <label className="custom-control-label" htmlFor="directionCheck" style={{ paddingTop: '2px' }}>Direction</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row d-flex align-items-center mb-3">
                                        <div className="col-xl-2 col-md-3 col-4">
                                            <div className="custom-control custom-checkbox">
                                                <input className="custom-control-input" type="checkbox" value={this.state.isTextSelect} id="textCheck"
                                                    onChange={(e) =>
                                                        this.onChangeAddName(
                                                            'isTextSelect',
                                                            this.state.isTextSelect ? false : true
                                                        )
                                                    }
                                                    checked={this.state.isTextSelect ? true : false}
                                                />
                                                <label className="custom-control-label" htmlFor="textCheck" style={{ paddingTop: '2px' }}>Text Message</label>
                                            </div>
                                        </div>
                                        <div className="col-xl-10 col-md-9 col-8">
                                            <input type='text' className='form-control' placeholder='Your message' value={this.state.textMessage} onChange={(e) =>
                                                this.onChangeAddName(
                                                    'textMessage',
                                                    e.target.value
                                                )
                                            } />
                                        </div>
                                    </div>
                                    <div className="row d-flex align-items-center mb-3">
                                        <div className="col-xl-2 col-md-3 col-4">
                                            <div className="custom-control custom-checkbox">
                                                <input className="custom-control-input" type="checkbox" value={this.state.isCouponSelect} id="couponCheck"
                                                    onChange={(e) =>
                                                        this.onChangeAddName(
                                                            'isCouponSelect',
                                                            this.state.isCouponSelect ? false : true
                                                        )
                                                    }
                                                    checked={this.state.isCouponSelect ? true : false}

                                                />
                                                <label className="custom-control-label" htmlFor="couponCheck" style={{ paddingTop: '2px' }}>Coupon</label>
                                            </div>
                                        </div>
                                        <div className="col-xl-10 col-md-9 col-8">
                                            <input type='text' className='form-control' value={this.state.couponText} onChange={(e) =>
                                                this.onChangeAddName(
                                                    'couponText',
                                                    e.target.value
                                                )
                                            } />
                                        </div>
                                    </div>
                                    <div className="row d-flex align-items-center mb-3">
                                        <div className="col-xl-2 col-md-3 col-4">
                                            <div className="custom-control custom-checkbox">
                                                <input className="custom-control-input" type="checkbox" value={this.state.isDealSelect} id="dealCheck"
                                                    onChange={(e) =>
                                                        this.onChangeAddName(
                                                            'isDealSelect',
                                                            this.state.isDealSelect ? false : true
                                                        )
                                                    }
                                                    checked={this.state.isDealSelect ? true : false}
                                                />
                                                <label className="custom-control-label" htmlFor="dealCheck" style={{ paddingTop: '2px' }}>Deal</label>
                                            </div>
                                        </div>
                                        <div className="col-xl-10 col-md-9 col-8">
                                            <input type='text' className='form-control' value={this.state.dealText} onChange={(e) =>
                                                this.onChangeAddName(
                                                    'dealText',
                                                    e.target.value
                                                )
                                            } />
                                        </div>
                                    </div>

                                    <div className="row d-flex align-items-center mb-3">
                                        <div className="col-xl-2 col-md-3 col-4">
                                            <div className="custom-control custom-checkbox">
                                                <input className="custom-control-input" type="checkbox" value={this.state.isOrderNowSelect} id="orderCheck"
                                                    onChange={(e) =>
                                                        this.onChangeAddName(
                                                            'isOrderNowSelect',
                                                            this.state.isOrderNowSelect ? false : true
                                                        )
                                                    }
                                                    checked={this.state.isOrderNowSelect ? true : false}
                                                />
                                                <label className="custom-control-label" htmlFor="orderCheck" style={{ paddingTop: '2px' }}>Order now</label>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row d-flex align-items-center mb-3">
                                        <div className="col-xl-2 col-md-3 col-4">
                                            <div className="custom-control custom-checkbox">
                                                <input className="custom-control-input" type="checkbox" value={this.state.isBookForAppointmentSelect} id="appointmentCheck"
                                                    onChange={(e) =>
                                                        this.onChangeAddName(
                                                            'isBookForAppointmentSelect',
                                                            this.state.isBookForAppointmentSelect ? false : true
                                                        )
                                                    }
                                                    checked={this.state.isBookForAppointmentSelect ? true : false}
                                                />
                                                <label className="custom-control-label" htmlFor="appointmentCheck" style={{ paddingTop: '2px' }}>Book for Appointment</label>
                                            </div>
                                        </div>
                                        {
                                            this.state.isBookForAppointmentSelect ?
                                                <div>
                                                    <div className='form-group for bookAppointmentService'>
                                                        <label htmlFor='businessPhone' className='form-control-label'>Book Appointment Service</label>
                                                        <select id="subscriptionPlan" class="form-control custom-select" required
                                                            value={this.state.bookAppointmentService}
                                                            type='select'
                                                            onChange={(e) => this.onChangeAddName('bookAppointmentService', e.target.value)}
                                                        >
                                                            {BookAppointmentService.map((value) => (
                                                                <option value={value}>
                                                                    {value}
                                                                </option>
                                                            ))}
                                                        </select>
                                                        {/* {
                                                            formErrbookAppointmentService?
                                                        } */}
                                                    </div>
                                                </div>
                                                : ''
                                        }
                                    </div>

                                    <div className="row d-flex align-items-center d-flex mb-3">
                                        <div className="col-xl-2 col-md-3 col-4">
                                            <div className="custom-control custom-checkbox">
                                                <input className="custom-control-input" type="checkbox" value={this.state.isCallForQuoteSelect} id="quoteCheck"
                                                    onChange={(e) =>
                                                        this.onChangeAddName(
                                                            'isCallForQuoteSelect',
                                                            this.state.isCallForQuoteSelect ? false : true
                                                        )
                                                    }
                                                    checked={this.state.isCallForQuoteSelect ? true : false}
                                                />
                                                <label className="custom-control-label" htmlFor="quoteCheck" style={{ paddingTop: '2px' }}>Contact Us for quote</label>
                                            </div>
                                        </div>
                                        {
                                            this.state.isCallForQuoteSelect ?
                                                <div>
                                                    <div className='form-group for quote service'>
                                                        <label htmlFor='businessPhone' className='form-control-label'>Request Quote Service</label>
                                                        <select id="subscriptionPlan" class="form-control custom-select" required
                                                            value={this.state.requestQuoteService}
                                                            type='select'
                                                            onChange={(e) => this.onChangeAddName('requestQuoteService', e.target.value)}
                                                        >
                                                            {QuoteService.map((value) => (
                                                                <option value={value}>
                                                                    {value}
                                                                </option>
                                                            ))}
                                                        </select>
                                                        {/* <span style={{ color: 'red' }}></span> */}
                                                    </div>
                                                </div>
                                                : ''
                                        }
                                    </div>

                                    <div className="row d-flex align-items-center mb-3">
                                        <div className="col-xl-2 col-md-3 col-4">
                                            <div className="custom-control custom-checkbox">
                                                <input className="custom-control-input" type="checkbox" value={this.state.isEcommerceSelect} id="ecommerceCheck"
                                                    onChange={(e) =>
                                                        this.onChangeAddName(
                                                            'isEcommerceSelect',
                                                            this.state.isEcommerceSelect ? false : true
                                                        )
                                                    }
                                                    checked={this.state.isEcommerceSelect ? true : false}
                                                />
                                                <label className="custom-control-label" htmlFor="ecommerceCheck" style={{ paddingTop: '2px' }}>E-commerce</label>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                            {/* Action Button */}
                            <div className="btn-wrapper mt-4">
                                {/* <button className="btn btn-secondary">Approve</button> */}
                                <button type="submit" className="btn btn-primary" onClick={(e) => this.handleSubmit()}>Update</button>
                            </div>
                        </div>
                    </div>
                </div>

                {
                    this.props.loading ?
                        <div className='loader-wrapper'>
                            <ReactLoading type="spin" color="#0074d9" className='loader-spiner' height={100} width={70} />
                        </div> : ''
                }

                <footer className="footer pt-0">
                    <div className="row align-items-center justify-content-lg-between">
                        <div className="col-lg-6">
                            <div className="copyright text-center text-lg-left  text-muted mt-4 ml-4">
                                &copy; 2021 ServiceHubConnect
                            </div>
                        </div>
                    </div>
                </footer>
            </div >
        )
    }
}

const mapPropsToState = (state) => {
    return {
        success: state.couponRanking.success,
        error: state.couponRanking.error,
        loading: state.couponRanking.loading,
        categorylisting: state.category.categorylisting,
        subcategorylisting: state.subCategory.subcategorylisting,
        businessDetails: state.couponRanking.businessDetails,
        subscriptionPlanlist: state.advertiseSubscription.subscriptionPlanlist
    }
}
const mapDispatchToProps = dispatch => {
    return {
        fetchCategory: () => dispatch(actions.fetchcategoryListAction()),
        fetchsubCategoryListAction: (data) => dispatch(actions.fetchsubCategoryListAction(data)),
        advertiseSubscriptionList: (data) => dispatch(actions.advertiseSubscriptionList(data)),
        getCouponrankingBusinessDetails: (data) => dispatch(actions.getCouponrankingBusinessDetails(data)),
        editCouponRankingBusiness: (data, history) => dispatch(actions.editCouponRankingBusiness(data)),
    }
}

export default connect(
    mapPropsToState,
    mapDispatchToProps
)(withSnackbar(withRouter(EditCouponrankingBusiness)))